<template>
  <div class="BGS">
    <div class="title">账号安全</div>
    <div class="from">
      <div class="from_title">修改密码</div>
      <div class="from_ipt">
        <input type="text" class="y_ipt" v-model="ipt1" placeholder="原始密码">
      </div>
      <div class="from_ipt">
        <input :type="type==false?'text':'password'" v-model="ipt2" class="y_ipt" placeholder="新密码">
        <img src="@/assets/img/display_icon.png" class="display_block" alt="" v-show="isshow==false"
          @click="showpass()">
        <img src="@/assets/img/hidden_icon.png" class="overflow_hidden" alt="" v-show="isshow==true"
          @click="showpass()">
      </div>
      <div class="from_ipt">
        <input :type="type1==false?'text':'password'" v-model="ipt3" class="y_ipt" placeholder="确认新密码">
        <img src="@/assets/img/display_icon.png" class="display_block" alt="" v-show="isshow1==false"
          @click="showpass1()">
        <img src="@/assets/img/hidden_icon.png" class="overflow_hidden" alt="" v-show="isshow1==true"
          @click="showpass1()">
      </div>

    </div>
    <div class="Submit" @click="Submit()">确定</div>
  </div>
</template>

<script>
import Axios from "axios";
export default {
   metaInfo(){
     return{
        title: '修改密码',
        meta: [
            {
                name: 'keywords',
                content: '鹰眼投诉_消费投诉维权平台,集体投诉,商家投诉,权威帮帮团,最新投诉报道,打假维权,质量曝光,客服咨询'
            },
            {
                name: 'description',
                content:'鹰眼投诉_消费投诉维权平台是驱动中国旗下的投诉平台,快速解决消费投诉,投诉维权,共享服务投诉,购物平台投诉,旅游出行投诉,住宿投诉,娱乐生活投诉,教育培训投诉,金融支付投诉等,拥有海量企业库,各领域专家,专业律师团队及权威帮帮团来帮助消费者。'
            }
        ]
     }     
    },
  data() {
    return {
      isshow: true,
      type: "text",
      isshow1: true,
      type1: "text",
      token: localStorage.getItem("token"),
      ipt1:'',    //原密码
      ipt2:'',
      ipt3:'',
    };
  },
  methods: {
    Submit() {
      var that=this;
      Axios.post("https://weixin.qudong.com/api/user/change_pass", {
          token: that.token,
          password:that.ipt1,
          new_password:that.ipt2,
          repeat_password:that.ipt3,
        }).then(function (res) {
          if (res.data.code == 200) {
            that.$message.success('修改'+res.data.msg);
          }else{
            that.$message.success(res.data.msg);
          }
        });
    },
    showpass() {
      this.isshow = !this.isshow;
      this.type = !this.type;
    },
    showpass1() {
      this.isshow1 = !this.isshow1;
      this.type1 = !this.type1;
    },
  },
};
</script>

<style scoped>
.BGS {
  width: 960px;
  background: #fff;
  margin-left: 20px;
}
.title {
  width: 100%;
  height: 76px;
  text-align: center;
  line-height: 76px;
  color: #fefefe;
  background: #1f72d9;
  font-size: 24px;
}
.from {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}
.from_title {
  font-size: 20px;
  color: #333333;
  margin-bottom: 30px;
}
.y_ipt {
  width: 336px;
  height: 40px;
  border: 1px solid #999999;
  border-radius: 5px;
  padding-left: 15px;
  margin-top: 20px;
}
.code {
  margin-top: 20px;
}
.codeipt {
  width: 196px;
  height: 42px;
  border: 1px solid #666666;
  border-radius: 5px;
  margin-right: 30px;
}
.codebtn {
  width: 119px;
  height: 42px;
  background: #1f72d9;
  border-radius: 5px;
  border: none;
  color: #fff;
  cursor: pointer;
}
.Submit {
  width: 336px;
  height: 42px;
  background: #1f72d9;
  border-radius: 5px;
  margin: 30px auto;
  text-align: center;
  line-height: 42px;
  color: #fff;
  cursor: pointer;
}
.from_ipt {
  position: relative;
}
.display_block {
  width: 19px;
  height: 12px;
  position: absolute;
  right: 10px;
  top: 35px;
  cursor: pointer;
}
.overflow_hidden {
  width: 19px;
  height: 17px;
  position: absolute;
  right: 10px;
  top: 33px;
  cursor: pointer;
}
</style>